<template>
  <div class="recommend">
    <div class="recommend-title">
      <img src="../../assets/user/recommend-title-icon.png" alt="" />
      <span class="title">为你推荐</span>
      <span>看看大家都在都在买的商品</span>
    </div>
    <div class="recommend-content">
      <div
        class="wares-node active"
        v-for="(item, index) in list"
        :key="index"
        @click="handleDetailClick(item.storeId, item.spuId)"
      >
        <div class="wares-node-img">
          <Uimage :src="item.defaultImg" />
        </div>
        <div class="wares-node-moeny">
          <div class="wares-node-moeny-title">
            <span>{{ item.spuName }}{{ item.spuName }}</span>
          </div>
          <div class="wares-node-moeny-number">
            <span class="integer">{{ Number(item.salePrice).toFixed(2) }}</span>
            <Uimage src="../../assets/user/ticket.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      list: '',
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    handleDetailClick(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    getlist() {
      get('api/welcome/selRecommendSku?pageNum=1&pageSize=4').then((res) => {
        this.list = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.recommend {
  width: 700px;
  background: #fff;

  &-title {
    height: 35px;
    padding: 5px;
    padding-left: 20px;
    color: #606060;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .title-other {
      display: inline-block;
      float: right;
      margin-right: 20px;
    }

    > span {
      margin-left: 10px;
    }
    img {
      width: 19px;
      height: 20px;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin-top: 10px;
    .wares-node {
      margin-top: 10px;
      width: 150px;
      height: 240px;
      margin-left: 10px;
      border: 1px solid #e2ecf6;
      .wares-node-img {
        width: 150px;
        cursor: pointer;
        height: 160px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .wares-node-moeny {
        .wares-node-moeny-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          padding: 10px 8px;
          cursor: pointer;
          color: #333;
        }
        .wares-node-moeny-number {
          margin-left: 10px;
          margin-top: 7px;
          color: #e67e1f;
          position: relative;
          font-weight: 600;
          img {
            position: absolute;
            right: 10px;
          }
        }
      }
    }
  }
}
</style>
