<template>
  <div class="right">
    <centered-user></centered-user>
    <div class="right-other">
      <div class="right-other-left">
        <centered-order></centered-order>
        <centered-recommend></centered-recommend>
      </div>
      <div class="right-other-right">
        <Centerde_manager />
        <centerde-follow></centerde-follow>
        <centerde-used></centerde-used>
      </div>
    </div>
  </div>
</template>
<script>
import user from './centered-user';
import order from './centered-order';
import recommend from './centered-recommend';
import follow from './centerde-follow';
import used from './centerde-used';
import Centerde_manager from './centerde-manager';
export default {
  components: {
    'centered-user': user,
    'centered-order': order,
    'centered-recommend': recommend,
    'centerde-follow': follow,
    'centerde-used': used,
    Centerde_manager,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 1006px;
  height: auto;
  padding-left: 27px;

  .right-other {
    margin-top: 30px;
    display: inline-flex;
    width: 100%;

    .right-other-left {
      width: 700px;
    }

    .right-other-right {
      width: 311px;
      margin-left: 15px;
    }
  }
}
</style>
