<template>
  <div class="follow" v-if="list!=undefined">
    <div class="follow-title">
      <div class="manager">
        <img src="../../assets/user/manager.png" alt="" />
        <span>客户经理</span>
      </div>
      <div>
        <el-tooltip
          class="item"
          effect="light"
          content="更换客户经理"
          placement="top-start"
        >
          <div @click="genghuan" class="tiaohuan">
            <i class="el-icon-sort"></i>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="name">
      <div>姓名:</div>
      <div class="name1">{{ list.memberName }}</div>
    </div>
    <div class="name">
      <div class="">手机号:</div>
      <div class="name1">{{ list.phone }}</div>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      list: '',
    };
  },
  created() {
    this.getmanager();
  },
  methods: {
    getmanager() {
      get('api/account/getMyManagerInfo').then((res) => {
        this.list = res.data.data;
      });
    },
    genghuan() {
      this.$confirm('是否更换客户经理?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        get('api/account/checkOrderIsNotFinishedByMemberId').then((res) => {
          if (res.data.data == true) {
            this.$message.warning({
              message: '请先完成目前订单！',
            });
          } else if (res.data.data == false) {
            this.savemanager();
          }
        });
      });
    },
    savemanager() {
      get('api/account/saveApplyAdjustManager').then((res) => {
        if (res.data.msg == '申请成功') {
          this.$message.success({
            message: '申请成功',
          });
        } else {
          this.$message.error({
            message: res.data.msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.follow {
  height: auto;
  width: 291px;
  background: #fff;

  &-title {
    height: 35px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 10px;
    color: #606060;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .manager {
      display: flex;
      align-items: center;
      span {
        margin-left: 7px;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
    .tiaohuan {
      cursor: pointer;
    }
  }
  .name {
    height: 35px;
    padding: 3px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    .name1 {
      margin-left: 10px;
    }
  }
}
</style>
