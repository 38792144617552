<template>
  <div class="follow">
    <div class="follow-title">
      <img src="../../assets/user/follow-title-icon.png" alt="" />
      <span>我的关注</span>
    </div>
    <div class="follow-content">
      <div class="content-item">
        <div class="count">
          <span>{{ shangpin }}</span>
        </div>
        <span @click="shang">商品关注</span>
      </div>

      <div class="content-item">
        <div class="count">
          <span>{{ dianpu }}</span>
        </div>
        <span @click="dian">店铺关注</span>
      </div>

<!--      <div class="content-item">-->
<!--        <div class="count">-->
<!--          <span>{{ zuji }}</span>-->
<!--        </div>-->
<!--        <span @click="zu">我的足迹</span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      dianpu: '0',
      zuji: '0',
      shangpin: '0',
    };
  },
  created() {
    //this.getmemberid();
    this.getshangpin();
    this.getdianpu();
    this.getzuji();
  },
  methods: {
    shang() {
      this.$router.push({
        name: 'wares',
      });
    },
    dian() {
      this.$router.push({
        name: 'store',
      });
    },
    zu() {
      this.$router.push({
        name: 'route',
      });
    },
    getzuji() {
      get('api/collectspu/selCollectSpuList').then((res) => {
        this.zuji = res.data.data.list.length;
      });
    },
    getshangpin() {
      get('api/collectspu/selColleectSpuList').then((res) => {
        this.shangpin = res.data.data.list.length;
      });
    },
    getdianpu() {
      get('api/collectStore/selCollectStoreList').then((res) => {
        this.dianpu = res.data.data.list.length;
      });
    },
    // getmemberid() {
    //   get('api/member/selMemberInfo').then((res) => {
    //     this.selSpuCount(res.data.data.memberId);
    //     this.selStoreCount(res.data.data.memberId);
    //     this.selBrowseCount(res.data.data.memberId);
    //   });
    // },
    // selSpuCount(e) {
    //   get('welcome/selSpuCount?memberId=' + e + '').then((res) => {
    //     console.log(res);
    //   });
    // },
    // selStoreCount(e) {
    //   get('welcome/selStoreCount?memberId=' + e + '').then((res) => {
    //     console.log(res);
    //   });
    // },
    // selBrowseCount(e) {
    //   get('welcome/selBrowseCount?memberId=' + e + '').then((res) => {
    //     console.log(res);
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.follow {
  height: auto;
  width: 291px;
  background: #fff;
  margin-top: 20px;
  &-title {
    height: 35px;
    padding: 5px;
    padding-left: 20px;
    color: #606060;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    > span {
      margin-left: 10px;
    }
    img {
      width: 19px;
      height: 20px;
    }
  }

  &-content {
    width: calc(100% - 40px);
    padding-top: 1px;
    margin-bottom: 20px;

    padding: 0px 20px;

    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 76px);
    grid-gap: 10px 0px;

    .content-item {
      display: inline-block;
      width: 76px;
      text-align: center;
      margin: 20px 0px;
      .count {
        font-size: 18px;
        font-weight: 600;
      }
      > span {
        display: inline-block;
        margin-top: 10px;
        cursor: pointer;
      }
      position: relative;
      &:last-of-type {
        &:after {
          background: none;
        }
      }
      &:after {
        position: absolute;
        top: 0px;
        right: -3px;
        content: '';
        display: inline-block;
        height: 100%;
        width: 1px;
        background: transparent url('../../assets/user/follow-division.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
