<template>
  <div class="user-top">
    <div class="user-block">
      <div class="user-info">
        <div class="user-portrait">
          <Uimage :src="headPhoto" />
        </div>
        <!-- <span class="user-grade">V1</span> -->
        <div class="user-name">
          <span>{{ name }}</span>
        </div>
      </div>
      <div class="use-other">
        <button>
          <svg>
            <text class="dianji" x="7" y="16" @click="shouhuo">收货地址</text>
          </svg>
        </button>
        <button>
          <svg>
            <text class="dianji" x="7" y="16" @click="xiugai">修改资料</text>
          </svg>
        </button>
      </div>
    </div>
    <div class="user-assets">
      <!-- <div class="user-hide">
        <img src="../../assets/user/user-hide.png" />
      </div> -->

      <div class="user-assets-item">
        <div class="user-assets-number">
          <span>{{ couponNum }}</span>
        </div>
        <div class="user-assets-name">
          <span>优惠券</span>
        </div>
        <div class="user-assets-text">
          <span @click="youhuiquan">查看</span>
        </div>
      </div>

      <div class="user-assets-item">
        <div class="user-assets-number">
          <span>{{ score }}</span>
        </div>
        <div class="user-assets-name">
          <span>积分</span>
        </div>
        <div class="user-assets-text" v-if="loding">
          <i class="el-icon-loading"></i>
        </div>
        <div class="user-assets-text" v-else>
          <span v-if="sign">已签到</span>
          <span v-else @click="qiandao">签到</span>
        </div>
      </div>

      <div class="user-assets-item">
        <div class="user-assets-number">
          <span>￥{{ Number(money).toFixed(2) }}</span>
        </div>
        <div class="user-assets-name">
          <span>余额</span>
        </div>
        <div class="user-assets-text">
          <span @click="dialogTableVisible = true">详情</span>
        </div>
      </div>

      <div class="user-assets-item">
        <div class="user-assets-number">
          <span>{{ gouwu }}</span>
        </div>
        <div class="user-assets-name">
          <span>购物卡</span>
        </div>
        <div class="user-assets-text">
          <span @click="gouwuka">查看</span>
        </div>
      </div>

      <div class="user-assets-item">
        <div class="user-assets-number">
          <span>{{ Number(moneyshouxin).toFixed(2) }}</span>
        </div>
        <div class="user-assets-name">
          <span>授信额度</span>
        </div>
        <div class="user-assets-text">
          <span @click="shouxin">查看</span>
        </div>
      </div>
    </div>
    <el-dialog title="余额详情" :visible.sync="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column
          property="storeName"
          label="店铺名称"
        ></el-table-column>
        <el-table-column property="balance" label="当前余额"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      couponNum: 0,
      name: '',
      headPhoto: '',
      money: 0,
      moneyshouxin: 0,
      score: 0,
      gouwu: 0,
      mes: '',
      status: 3,
      sign: false,
      gridData: [],
      dialogTableVisible: false,
      loding:false,
    };
  },
  created() {
    this.getorder();
    this.getmoney();
    this.getCouponNum();
    this.getedu();
    this.getdata1();
    this.getqiandao();
    this.getyue();
  },
  methods: {
    jifen() {
      this.$router.push({
        name: 'Integral',
      });
    },
    //余额详情
    getyue() {
      post('api/storeaccount/getShopMemberAccountStorelist').then((res) => {
        res.data.data.forEach((req) => {
          req.balance = Number(req.balance).toFixed(2);
        });
        this.gridData = res.data.data;
      });
    },
    //签到
    qiandao() {
      this.loding=true
      post('api/account/setMemberScore', {
        scoreValue: '',
        changeType: 0,
        sourceType: 2,
        sourceRemark: '',
      }).then((res) => {
      this.loding=false
        if (res.data.msg == '积分更新成功') {
          this.$message.success({
            message: '签到成功',
          });
          this.getqiandao();
          this.getorder();
        } else {
          this.$message.error({
            message: '签到失败',
          });
        }
      });
    },
    //判断是否签到
    getqiandao() {
      get('api/account/getAccountIsClockin').then((res) => {
        this.sign = res.data.data;
      });
    },
    //购物卡
    getdata1() {
      get(
        'api/shopcoupon/selectShopCardRecord?mes=' +
          this.mes +
          '&status=' +
          this.status
      ).then((res) => {
        this.gouwu = res.data.data.total;
      });
    },
    //授信额度
    getedu() {
      get('api/credit/getcreditnum').then((res) => {
        this.moneyshouxin = res.data.data.creditLine;
      });
    },
    gouwuka() {
      this.$router.push({
        name: 'Shopcard',
      });
    },
    youhuiquan() {
      this.$router.push({
        name: 'Youhui',
      });
    },
    shouxin() {
      this.$router.push({
        name: 'Shouxin',
      });
    },
    getorder() {
      get('api/member/selMemberInfo').then((res) => {
        this.name = res.data.data.nickName; // 用户名
        this.headPhoto = res.data.data.headPhoto; // 头像
        // 积分格式化处理
        if (res.data.data.scoreCount == null) {
          this.score = 0;
        } else {
          this.score = res.data.data.scoreCount;
        }
      });
    },
    getmoney() {
      get('api/account/selAccount').then((res) => {
        this.money = res.data.data.balance;
      });
    },
    getCouponNum() {
      get('api/coupon/selectShopMemberCouponNum').then((res) => {
        this.couponNum = res.data.data;
      });
    },
    shouhuo() {
      this.$router.push({
        name: 'Address',
      });
    },
    xiugai() {
      this.$router.push({
        name: 'User',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dianji {
  cursor: pointer;
}
.user-top {
  height: 233px;
  width: 100%;
  position: relative;
  padding-top: 1px;

  .user-block {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 249px;
    background: transparent url('../../assets/user/portrait-background.png')
      no-repeat;
    background-size: 100% 100%;
    border-radius: 25px;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.248);
    z-index: 1;

    .user-info {
      width: 100%;
      height: 168px;
      position: relative;
      padding-top: 1px;

      .user-portrait {
        border-radius: 50%;
        border: 2px solid #fffffd;
        box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.248),
          2px -2px 5px rgba(0, 0, 0, 0.248), 2px 2px 5px rgba(0, 0, 0, 0.248),
          -2px 2px 5px rgba(0, 0, 0, 0.248);
        width: 71px;
        height: 71px;
        margin: 0px auto;
        margin-top: 30px;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .user-grade {
        position: absolute;
        display: inline-block;
        min-width: 27px;
        height: 24px;
        background: transparent url('../../assets/user/user-grade.png')
          no-repeat;
        background-size: 100% 100%;
        top: 79px;
        right: 70px;
        // border-radius: 20px;
        padding: 0px 6px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        line-height: 22px;
      }

      .user-name {
        text-align-last: center;
        width: 80px;
        margin: 0px auto;
        margin-top: 20px;
        font-weight: 600;
        color: #4d9cc3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .use-other {
      height: 66px;
      width: 100%;
      text-align: center;
      padding: 20px 0px;

      > button {
        &:first-child {
          margin-right: 5px;
        }

        &:last-child {
          margin-left: 5px;
        }

        display: inline-block;
        border: 1px solid #fff;
        border-radius: 25px;
        width: 90px;
        height: 25px;
        color: #fff;
        font-size: 12px;
        background: transparent;

        > svg {
          width: 100%;
          height: 100%;
          fill: #fff;
          font-size: 16px;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .user-assets {
    height: 202px;
    width: calc(100% - 259px);
    padding-left: 259px;
    margin: 16.5px 0px;
    background: #fff;
    display: inline-flex;
    position: relative;
    .user-hide {
      position: absolute;
      height: 14px;
      width: 22px;
      right: 30px;
      top: 10px;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    .user-assets-item {
      width: 92px;
      height: calc(100% - 40px);
      padding: 20px 30px;
      text-align: center;

      .user-assets-number {
        color: #ff902a;
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px;
      }

      .user-assets-name {
        margin-top: 20px;
      }

      .user-assets-text {
        margin-top: 30px;
        color: #1c52fd;
        span {
          cursor: pointer;
        }
      }

      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        display: inline-block;
        width: 1px;
        height: 100%;
        background: transparent url('../../assets/user/user-division.png')
          no-repeat;
        background-size: 100% 100%;
      }

      &:last-of-type {
        &:after {
          background: none;
        }
      }
    }
  }
}
</style>
