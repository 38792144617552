<template>
  <div class="used">
    <div class="used-title">
      <div class="changgou">
        <img src="../../assets/user/used-title-icon.png" alt="" />
        <span class="title">常购清单</span>
      </div>
      <div class="title-other" @click="more">
        <span>更多<i class="el-icon-arrow-right"></i></span>
      </div>
    </div>
    <div class="used-content">
      <div
        class="content-item"
        v-for="(item, index) in list"
        :key="index"
        @click="detail(item.storeId, item.spuId)"
      >
        <div class="content-item-image">
          <Uimage :src="item.defaultImg" />
        </div>
        <div class="content-item-text">
          <div class="name">
            <span>{{ item.name }}</span>
          </div>
          <div class="money">
            <span>{{ Number(item.salePrice).toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    getlist() {
      get('api/orderbasic/getoftensku?pageNum=1&pageSize=5').then((res) => {
        this.list = res.data.data.list;
      });
    },
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    more() {
      this.$router.push({
        name: 'Changgou',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.used {
  height: auto;
  width: 291px;
  background: #fff;

  &-title {
    height: 35px;
    padding: 5px;
    padding-left: 20px;
    color: #606060;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: 600;
      font-size: 18px;
      margin-left: 10px;
    }

    .title-other {
      display: inline-block;
      float: right;
      margin-right: 20px;
      cursor: pointer;
    }

    > span {
      margin-left: 10px;
    }
    .changgou {
      display: flex;
      align-items: center;
      img {
        width: 19px;
        height: 20px;
      }
    }
  }

  &-content {
    padding: 20px 27px;
    padding-top: 40;

    .content-item {
      width: 100%;
      height: 95px;
      border-bottom: 1px dotted #d0d0d0;
      display: inline-flex;
      flex-direction: row;
      &:last-of-type {
        border-bottom: none;
      }

      &-image {
        width: 76px;
        height: 76px;
        cursor: pointer;
        > img {
          width: 100%;
          height: 100%;
        }
      }

      &-text {
        flex: 100;
        margin-left: 20px;
        cursor: pointer;

        .name {
          cursor: pointer;
          font-size: 16px;
          color: #333;
          display: inline-block;
          width: 141px;
          height: 43px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 2; //显示的行
        }

        .money {
          cursor: pointer;

          margin-top: 10px;
          color: #e78023;
          font-size: 16px;
          font-weight: 600;

          &:before {
            content: '￥';
          }
        }
      }
    }
  }
}
</style>
